import React from "react";
import Styled from "styled-components";
import { ITitleProps as Props } from "../interface";

const Title: React.FC<Props> = React.memo(({ type, tag, align, color, text }) => {
  const CustomTag = tag !== "" ? tag : "p";
  return (
    <StyledHeading type={type} align={align} color={color}>
      <CustomTag>{text}</CustomTag>
    </StyledHeading>
  );
});

function handleHeaderFontSize(type: Props["type"]) {
  switch (type) {
    case "SCREEN_TITLE":
      return "32px";
    case "SECTION_TITLE":
      return "26px";
    case "BLOCK_TITLE":
      return "22px";
    case "SUB_BLOCK_TITLE":
      return "18px";
    case "SMALL_BLOCK_TITLE":
      return "14px";
  }
}

const StyledHeading = Styled.div<Pick<Props, "type" | "color" | "align">>`
  margin: 0;
  font-size: ${({ type }) => handleHeaderFontSize(type)};
  color: ${({ color }) => color};
  text-align: ${({ align }) => align};
  font-family: roboto, sans-serif, arial;

  p {
    margin: 0 0 ${({ type }) =>
      type === "SMALL_BLOCK_TITLE" ? "4px" : "8px"};
  }
`;

export default Title;
