import React from "react";
import Styled from "styled-components";
import Input from "./Input";
import Button from "./Button";
import Title from "./Title";
import { IoClose, IoAdd } from "react-icons/io5";
import { IContentZoneEdit as Props } from "../interface";
import { useTranslation } from "react-i18next";

const ContentZoneEdit: React.FC<Props> = ({
  name,
  value,
  index,
  length,
  isError,
  placeholders,
  onAdd,
  onRemove,
  handleNameChange,
  handleSelectorChange,
}) => {
  const { t } = useTranslation();
  
  return (
    <StyledUrlEdit index={index} length={length}>
      <div className="contentZoneEditInputContainer">
        <div className="contentZoneEditBlock">
          <Title
            type={"SMALL_BLOCK_TITLE"}
            tag={"p"}
            text={t(`lang.contentZoneName`)}
            align={"left"}
            color={"#9a9a9a"}
          />
          <Input
            handleChange={handleNameChange}
            value={name}
            isError={isError}
            ariaLabel="contentzone-name"
            placeholder={placeholders?.[0] ?? ""}
          />
        </div>

        <div className="contentZoneEditBlock">
          <Title
            type={"SMALL_BLOCK_TITLE"}
            tag={"p"}
            text={t(`lang.selector`)}
            align={"left"}
            color={"#9a9a9a"}
          />
          <Input
            handleChange={handleSelectorChange}
            value={value}
            isError={isError}
            ariaLabel="contentzone-value"
            placeholder={placeholders?.[1] ?? ""}
          />
        </div>
      </div>
      {
        <div
          className={`contentZoneEditGroupContainer ${
            length > 1 && index === 0
              ? "contentZoneEditGroupContainerMultiple"
              : ""
          }`}
        >
          {index === length - 1 && (
            <div className="contentZoneEditContainer">
              <Button
                {...{
                  onClick: onAdd,
                  text: t(`lang.add`),
                  disabled: false,
                  icon: <IoAdd />,
                  buttonType: "ADD",
                  ariaLabel: "add-button",
                }}
              />
            </div>
          )}
          {index !== 0 && (
            <div className="contentZoneEditContainer">
              <Button
                {...{
                  onClick: onRemove,
                  text: t(`lang.remove`),
                  disabled: false,
                  icon: <IoClose />,
                  buttonType: "REMOVE",
                  ariaLabel: "remove-button",
                }}
              />
            </div>
          )}
        </div>
      }
    </StyledUrlEdit>
  );
};

const StyledUrlEdit = Styled.div<Pick<Props, "index" | "length">>`
  display: flex;
  margin-top: 12px;
  
  &:first-child {
    margin-top: 0
  }

  .contentZoneEditBlock:first-child {
    flex: 1 0 300px;
  }

  .contentZoneEditBlock {
    display: flex;
    flex-direction: column;
    flex: 0 1 100%;
  }

  .contentZoneEditInputContainer {
    display: flex;
    gap: 10px;
    flex: 0 1 100%;
  }

  .contentZoneEditGroupContainer {
    flex: ${({ index, length }) =>
      index === length - 1 ? "1 0 160px" : "1 0 75px"};
    display: flex;
    margin: 0 0 0 8px;
    gap: 10px;
  }

  .contentZoneEditContainer {
    flex: 1 0 auto;
    display: flex;
    align-items: flex-end;

    button {
      height: auto;
    }
  }

  .contentZoneEditContainer:first-child {
    flex: 1 0 auto;
  }

  .contentZoneEditContainer:last-child {
    flex: 1 1 auto;
  }

  .contentZoneEditGroupContainerMultiple {
    display: none;
  }
`;

export default ContentZoneEdit;
