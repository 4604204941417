import React, { memo } from "react";
import Styled from "styled-components";
import { ButtonType, IButtonProps as Props } from "../interface";

const Button: React.FC<Props> = memo(
  ({ onClick, disabled, text, icon, buttonType, ariaLabel }) => {
    return (
      <StyledButton
        onClick={onClick}
        disabled={disabled}
        buttonType={buttonType}
        aria-label={ariaLabel}
      >
        {icon}
        <span>{text}</span>
      </StyledButton>
    );
  }
);

const handleType = (type: ButtonType) => {
  switch (type) {
    case "ADD":
      return {
        color: "#333",
        background: "#EDF0F9",
      };
    case "REMOVE":
      return {
        color: "#C3696A",
        background: "#fedbdc",
      };
    case "GENERATE":
      return {
        color: "#fff",
        background: "#3A36F7",
      };
    case "COPY":
      return {
        color: "#fff",
        background: "#3A36F7",
      };
    default:
      return {
        color: "#333",
        background: "#EDF0F9",
      };
  }
};

const StyledButton = Styled.button<Props>`
  border: none;
  cursor: pointer;
  outline: none;
  border-radius: 8px;
  appearance: none;
  width: 100%;
  height: 100%;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:focus-visible {
    outline: 1px dashed darkorange;
  };
  padding: 13px 12px;
  background: ${({ buttonType }) => handleType(buttonType).background};
  color: ${({ buttonType }) => handleType(buttonType).color};
  font-weight: 600;
  pointer-events: ${({ disabled }) => {
    return disabled ? "disabled" : "";
  }};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  filter: ${({ disabled }) => (disabled ? "brightness(50%)" : "none")};
  &:hover {
    filter: ${({ disabled }) =>
      disabled ? "brightness(50%)" : "brightness(95%)"};
  }
`;

export default Button;
