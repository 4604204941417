import Styled from "styled-components";
import RadioMenu from "./RadioMenu";
import { NameSpaceValueType, INameSpace, IRadioMenuGroup as Props } from "../interface";
import { NameSpaceContext } from "../App";
import { useContext } from "react";

const RadioMenuGroup: React.FC<Props> = ({
  role,
}) => {
  const { nameSpaceValue, setNameSpaceValue } = useContext(NameSpaceContext)

  return (
    <StyledRadioMenuGroup role={role}>
      {nameSpaceValue.map((item: INameSpace, index: number) => (
        <RadioMenu
          key={`${item}_${index}`}
          text={item.value}
          selected={item.selected}
          onClick={(value: NameSpaceValueType) => {
            updateNameSpaceState(
              value,
              nameSpaceValue,
              setNameSpaceValue
            )
          }}
          role={role.replace(/Group/g, "")}
        />
      ))}
    </StyledRadioMenuGroup>
  )
};

const StyledRadioMenuGroup = Styled.div`
  display: flex;
  gap: 16px;
`;

function updateNameSpaceState (
  value: NameSpaceValueType,
  nameSpaceValue: INameSpace[],
  updateState: React.Dispatch<React.SetStateAction<INameSpace[]>>
) {
  const targetIndex = nameSpaceValue.findIndex(
    (nameSpace: INameSpace) => nameSpace.value === value
  );
  nameSpaceValue.map((nameSpace: INameSpace) => (nameSpace.selected = false));
  nameSpaceValue[targetIndex].selected = true;
  updateState([...nameSpaceValue]);
};

export default RadioMenuGroup;
