import { useState } from "react";
import {
  IActionValue,
  IContentZoneValue,
  IUrlConditionValue,
  NameSpaceValueType,
} from "../interface";

export function GenerateRandomString(length: number): string {
  return (+new Date() * Math.random()).toString(36).substring(0, length);
}

type CopiedValue = string | null;
type CopyFn = (text: string) => Promise<boolean>; // Return success
interface IActionName {
  name: string;
  isActionName: string;
}

export function useCopyToClipboard(): [CopiedValue, CopyFn] {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null);

  // const copy: CopyFn = async (text, callback) => {
  const copy: CopyFn = async (text) => {
    if (!navigator?.clipboard) {
      console.warn("Clipboard not supported");
      return false;
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      return true;
    } catch (error) {
      console.warn("Copy failed", error);
      setCopiedText(null);
      return false;
    }
  };

  return [copiedText, copy];
}

export function setResult(
  stateArray: IUrlConditionValue[],
  actionName: IActionValue,
  nameSpace: NameSpaceValueType,
  contentZoneArray: IContentZoneValue[],
  hasSitemap: boolean,
  setSingleState: React.Dispatch<React.SetStateAction<string>>,
  domainPlaceholder: string,
) {
  const urlConditionArray = stateArray.map((item): string => {
    let value = "";
    switch (item.matchType) {
      case "EXACT_MATCH":
        value =
          new RegExp("^" + item.value + "$") + ".test(window.location.href)";
        break;
      case "CONTAIN":
        value = new RegExp(item.value) + ".test(window.location.href)";
        break;
      case "START_WITH":
        value = new RegExp("^" + item.value) + ".test(window.location.href)";
        break;
      case "END_WITH":
        value = new RegExp(item.value + "$") + ".test(window.location.href)";
        break;
    }
    return value;
  });

  const actionNameValue = generateActionName(actionName);
  const isMatchValue = urlConditionArray.reverse().join(" || ");
  const contentZones = generateContentZones(contentZoneArray, hasSitemap);
  const result = generateResult(
    actionNameValue,
    nameSpace,
    isMatchValue,
    contentZones,
    hasSitemap,
    domainPlaceholder
  );

  setSingleState(result);
}

export function hasError(
  array: IActionValue[] | IUrlConditionValue[] | IContentZoneValue[]
): boolean {
  return array.some(
    (item: IActionValue | IUrlConditionValue | IContentZoneValue) =>
      item.isError === true
  );
}

function generateContentZones(
  contentZones: IContentZoneValue[],
  hasSitemap: boolean
) {
  const contentZoneArray = contentZones.map(
    (content: IContentZoneValue, index: number) => {
      return `      ${setIndentation(index, hasSitemap)}{name: "${
        content.name
      }", selector: "${content.value}" },${
        index === contentZones.length - 1 ? "" : "\n"
      }`;
    }
  );
  return contentZoneArray.join("");
}

function setIndentation(index: number, hasSitemap: boolean) {
  let space = "";
  const spaceChar = " ";
  if (hasSitemap) {
    if (index > 0) {
      space = spaceChar.repeat(14);
    }
  } else {
    if (index > 0) {
      space = spaceChar.repeat(2);
    }
  }
  return space;
}

function generateActionName(actionName: IActionValue): IActionName {
  const name = actionName.value.replace(/\s/g, "_");
  const isActionName =
    actionName.value.charAt(0).toUpperCase() + actionName.value.slice(1);
  return {
    name,
    isActionName,
  };
}

function generateResult(
  actionNameValue: IActionName,
  nameSpace: NameSpaceValueType,
  regExpValue: string,
  contentZones: string,
  hasSitemap: boolean,
  domainPlaceholder: string,
  ): string {
  console.log({ domainPlaceholder})
  if (hasSitemap) {
    if (nameSpace === "Evergage") {
      return `Evergage.init({
    cookieDomain: "${domainPlaceholder}"
}).then(() => {
    const config = {
        global: {
            onActionEvent: (actionEvent) => {
                return actionEvent;
            },
            contentZones: [],
            listeners: [],
        },
        pageTypeDefault: {
            name: "default"
        },
        pageTypes: [
            {
                name: "${actionNameValue.name}",
                action: "${actionNameValue.isActionName}",
                isMatch: () => ${regExpValue},
                contentZones: [
              ${contentZones}
                ]
            }
        ]
    };
    Evergage.initSitemap(config);
});`;
    }

    if (nameSpace === "SalesforceInteractions") {
      return `SalesforceInteractions.init({
    cookieDomain: "${domainPlaceholder}",
    consents: [{
        purpose: SalesforceInteractions.mcis.ConsentPurpose.Personalization,
        provider: "Example Consent Manager",
        status: SalesforceInteractions.ConsentStatus.OptIn
    }]
}).then(() => {
    const sitemapConfig = {
        global: {
            onActionEvent: (actionEvent) => {
                return actionEvent;
            },
            contentZones: [],
            listeners: [],
        },
        pageTypeDefault: {
            name: "default",
            interaction: {
                name: "Default Page"
            }
        },
        pageTypes: [
            {
                name: "${actionNameValue.name}",
                interaction: {
                    name: "${actionNameValue.isActionName}",
                },
                isMatch: () => ${regExpValue},
                contentZones: [
              ${contentZones}
                ]
            }
        ]
    };
    SalesforceInteractions.initSitemap(sitemapConfig);
});`;
    }
  } else {
    if (nameSpace === "Evergage") {
      return `{
    name: "${actionNameValue.name}",
    action: "${actionNameValue.isActionName}",
    isMatch: () => ${regExpValue},
    contentZones: [
  ${contentZones}
    ]
}`;
    }

    if (nameSpace === "SalesforceInteractions") {
      return `{
    name: "${actionNameValue.name}",
    interaction: {
        name: "${actionNameValue.isActionName}",
    },
    isMatch: () => ${regExpValue},
    contentZones: [
  ${contentZones}
    ]
}`;
    }
  }
  return "";
}
