import styled from "styled-components";

interface Props {
  space: number
}

const Spacer: React.FC<Props> = ({ space }) => {
  return <StyledSpacer space={space} />;
}

const StyledSpacer = styled.div<Props>`
  margin-top: ${({ space }) => `${space}px`};
`;

export default Spacer;
