import Styled from "styled-components";
import { IContainerProps as Props } from "../interface";

const Container: React.FC<Props> = ({
  children,
  width,
  maxWidth,
  marginTop,
}) => {
  return (
    <StyledContainer marginTop={marginTop} width={width} maxWidth={maxWidth}>
      {children}
    </StyledContainer>
  );
};

const StyledContainer = Styled.div<Props>`
    width: ${(props) => props.width};
    max-width: ${(props) => props.maxWidth};
    margin-left: auto;
    margin-right: auto;
    margin-top: ${(props) => props.marginTop};
    
    p {
      font-weight: bold;
    }
`;

export default Container;
