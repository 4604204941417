import { ReactNode } from "react";
import Styled from "styled-components";

interface Props {
  children: ReactNode
}

const Row: React.FC<Props> = ({ children }) => {
  return <StyledRow>{children}</StyledRow>;
}

const StyledRow = Styled.div`
    display: flex;
    flex-direction: column;
`;

export default Row;
