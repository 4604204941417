import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface Props {
  handleOnChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const Select: React.FC<Props> = ({
  handleOnChange,
}) => {
  const { t } = useTranslation();
  
  return (
    <StyledSelect name="regexType" onChange={handleOnChange} role="select">
      <option value="CONTAIN">{t(`lang.contain`)}</option>
      <option value="EXACT_MATCH">{t(`lang.exactMatch`)}</option>
      <option value="START_WITH">{t(`lang.startWith`)}</option>
      <option value="END_WITH">{t(`lang.endWith`)}</option>
    </StyledSelect>
  );
}

const StyledSelect = styled.select`
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #666;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
  width: 320px;
  height: 50px;
  padding-left: 16px;
  flex: 0 0 200px;
  background-color: #fff;

  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1.5em + 2px),
    calc(100% - 15px) calc(1.5em + 2px), calc(100% - 2.5em) 1em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;

  &:focus {
    background-image: linear-gradient(45deg, green 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, green 50%),
      linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 15px) 1.6em, calc(100% - 20px) 1.6em,
      calc(100% - 2.5em) 1em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    border-color: green;
    outline: 0;
  }

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
`;

export default Select;
