import { IoAlertCircle } from "react-icons/io5";
import styled from "styled-components";
import { IAlert as Props } from "../interface";

const Alert: React.FC<Props> = ({ hasAlert, messages }) => {
  return hasAlert ? (
    <StyledAlert>
      {messages.map((item: string, index: number) => (
        <div className="alertList" key={`${item}_${index}`}>
          <IoAlertCircle />
          <li key={item}>{item}</li>
        </div>
      ))}
    </StyledAlert>
  ) : (
    <></>
  );
};

const StyledAlert = styled.ul`
  border: 1px solid #f5c6cb;
  border-radius: 6px;
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  box-sizing: border-box;
  padding-left: 0;
  word-break: break-word;
  margin: 10px 0 10px;

  &:first-child {
    margin-top: 30px;
  }

  li {
    list-style: none;
    padding-left: 4px;
  }

  .alertList {
    display: flex;
    padding-left: 15px;
    align-items: center;
  }
`;
export default Alert;
