import React, { createContext, useState, Suspense } from "react";
import Styled from "styled-components";
import toast, { Toaster } from "react-hot-toast";
import {
  GenerateRandomString,
  hasError,
  setResult,
  useCopyToClipboard,
} from "./utils";
import {
  INameSpace,
  INameSpaceContext,
  IUrlConditionValue,
  MatchType,
  IContentZoneValue,
  IActionValue,
} from "./interface";
import Title from "./component/Title";
import Button from "./component/Button";
import Container from "./component/Container";
import UrlEdit from "./component/UrlEdit";
import Alert from "./component/Alert";
import ResultTextBox from "./component/ResultTextBox";
import RadioMenuGroup from "./component/RadioMenuGroup";
import Spacer from "./component/Spacer";
import Input from "./component/Input";
import ContentZoneEdit from "./component/ContentZoneEdit";
import Row from "./component/Row";
import { IoInformationCircleOutline } from "react-icons/io5";
import TitleContainer from "./component/TitleContainer";
import ReactTooltip from "react-tooltip";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import Switch from "react-switch";

export const NameSpaceContext = createContext({} as INameSpaceContext);
 
const changeLanguage = (lng: string) => {
  i18n.changeLanguage(lng);
};

function App() {
  // useState Hooks
  // Two Namespaces each of which has different methods
  const [nameSpaceValue, setNameSpaceValue] = 
    useState<INameSpace[]>([
      { value: "SalesforceInteractions", selected: true },
      { value: "Evergage", selected: false },
    ]);

  // actionName is used as a event name in Personalization. It could be any string values.
  const [actionName, setActionName] =
    useState<IActionValue>({
      value: '',
      isError: false,
    });

  // urlConditionArray is values which are input in the form.
  const [urlConditionArray, setUrlConditionArray] =
    useState<IUrlConditionValue[]>([
      {
        value: '',
        rowId: GenerateRandomString(5),
        isError: false,
        matchType: 'CONTAIN',
      }
    ]);

  // contentZoneArray is values which are input in the contentzone form.
  const [contentZoneArray, setContentZoneArray] = 
    useState<IContentZoneValue[]>([
      {
        rowId: GenerateRandomString(5),
        name: '',
        value: '',
        isError: false,
      },
    ]);

  // output is the final string values which is used inside Sitemap
  const [output, setOutput] = useState<string>('');

  // set copied value
  const [_, copy] = useCopyToClipboard();

  // set language mode
  const [checked, setCheck] = useState(true)
  const { t } = useTranslation();

  const copyResult = (message: string) => {
    toast.success(message, {
      id: "clipboard",
    });
    copy(output);
  }

  const generate = (hasSitemap: boolean, domainPlaceholder: string) => {
    const { value: currentNamespace } = nameSpaceValue.filter((ns: INameSpace) => ns.selected)[0];
    const { value } = actionName
    setActionName({ value, isError: value === '' });
    setUrlConditionArray(getUpdatedUrlConditionArray(urlConditionArray));
    setContentZoneArray(updatedContentZoneArray(contentZoneArray));

    hasError([actionName]) && setOutput('');
    hasError(getUpdatedUrlConditionArray(urlConditionArray)) && setOutput('');
    hasError(updatedContentZoneArray(contentZoneArray)) && setOutput('');

    !hasError([actionName]) &&
      !hasError(getUpdatedUrlConditionArray(urlConditionArray)) &&
      !hasError(updatedContentZoneArray(contentZoneArray)) &&
      setResult(
        urlConditionArray,
        actionName,
        currentNamespace,
        contentZoneArray,
        hasSitemap,
        setOutput,
        domainPlaceholder,
      );
  }

  return (
    <Suspense fallback={<p>Loading</p>}>
    
      <StyledSwitch>
        <Switch
          offColor='#000'
          onColor='#ddd'
          onHandleColor="#3A36F7"
          handleDiameter={20}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          borderRadius={30}
          onChange={(value) => {
            value ? changeLanguage('ja') : changeLanguage('en');
            return setCheck(value);
          }}
          checked={checked}
          uncheckedIcon={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 15,
                color: "white",
                paddingRight: 2
              }}
            >
              EN
            </div>
          }
          checkedIcon={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 15,
                color: "black",
                paddingRight: 1
              }}
            >
              JP
            </div>
          }
        />
      </StyledSwitch>

      <StyledApp>
        <Container width={"100%"} maxWidth={"960px"}>
          <Title
            type={"BLOCK_TITLE"}
            tag={"h1"}
            text={"Sitemap Generator"}
            align={"center"}
            color={"#444"}
          />
        </Container>

        <Container width={"100%"} maxWidth={"960px"}>
          <>
            <Title
              type={"SUB_BLOCK_TITLE"}
              tag={"p"}
              text={t('lang.namespace')}
              align={"left"}
              color={"#888"}
            />

            <NameSpaceContext.Provider 
              value={{
                nameSpaceValue,
                setNameSpaceValue
              }}
            >
              <RadioMenuGroup role={"radioGroup"} />
            </NameSpaceContext.Provider>

            <Spacer space={30} />

            <TitleContainer>
              <Title
                type={"SUB_BLOCK_TITLE"}
                tag={"p"}
                text={t('lang.actionName')}
                align={"left"}
                color={"#888"}
              />
              <div data-tip={t('lang.actionTip')}>
                  <IoInformationCircleOutline
                    color="#888"
                    size={20}
                    style={{ marginBottom: "1px" }}
                  />
                  <ReactTooltip
                    effect="float"
                    type="info"
                    place="right"
                    backgroundColor="#1a9fda"
                  />
              </div>
            </TitleContainer>
            <Input
              value={actionName.value}
              handleChange={(
                event: React.ChangeEvent<HTMLInputElement>
              ): void => {
                setActionName({
                  value: event.target.value,
                  isError: false,
                });
              }}
              isError={actionName.isError}
              placeholder="Home Page"
            />
            <Spacer space={30} />

            <Title
              type={"SUB_BLOCK_TITLE"}
              tag={"p"}
              text={t('lang.pageUrl')}
              align={"left"}
              color={"#888"}
            />
            {urlConditionArray.map((item, index) => (
              <UrlEdit
                key={`UrlEdit_${index}`}
                index={index}
                length={urlConditionArray.length}
                isError={item.isError}
                value={urlConditionArray[index].value}
                placeholder="https://salesforce.com"
                onChange={(event) =>
                  updateMatchType(
                    event,
                    urlConditionArray,
                    setUrlConditionArray,
                    index
                  )
                }
                handleChange={(event) => {
                  const cloneArray = [...urlConditionArray];
                  cloneArray[index].value = event.target.value;
                  setUrlConditionArray(cloneArray);
                }}
                onAdd={() => updateUrlValue(setUrlConditionArray)}
                onRemove={() => {
                  const newDataArray = urlConditionArray.filter((_, i) => i !== index);
                  setUrlConditionArray(newDataArray);
                }}
              />
            ))}

            <Spacer space={30} />

            <Title
              type={"SUB_BLOCK_TITLE"}
              tag={"p"}
              text={t('lang.contentZone')}
              align={"left"}
              color={"#888"}
            />
            {contentZoneArray.map((item, index) => (
              <ContentZoneEdit
                key={`ContentZoneEdit_${index}`}
                index={index}
                length={contentZoneArray.length}
                isError={item.isError}
                onAdd={() =>
                  setContentZoneArray((contentZoneArray) => {
                    return [
                      ...contentZoneArray,
                      {
                        rowId: GenerateRandomString(5),
                        name: '',
                        value: '',
                        isError: false,
                      },
                    ];
                  })
                }
                onRemove={() => {
                  const newDataArray = contentZoneArray.filter((_, i) => {
                    return i !== index;
                  });
                  setContentZoneArray(newDataArray);
                }}
                handleNameChange={(event) => {
                  const cloneArray = [...contentZoneArray];
                  cloneArray[index].name = event.target.value;
                  setContentZoneArray(cloneArray);
                }}
                handleSelectorChange={(event) => {
                  const cloneArray = [...contentZoneArray];
                  cloneArray[index].value = event.target.value;
                  setContentZoneArray(cloneArray);
                }}
                name={contentZoneArray[index].name}
                value={contentZoneArray[index].value}
                placeholders={["Campaign Area", ".header >.campaign-area"]}
              />
            ))}

            <Row>
              <Alert
                hasAlert={hasError([actionName])}
                messages={[t('lang.errorAction')]}
              />
              <Alert
                hasAlert={hasError(urlConditionArray)}
                messages={[t('lang.errorUrl')]}
              />
              <Alert
                hasAlert={hasError(contentZoneArray)}
                messages={[t('lang.errorContentZone')]}
              />
            </Row>

            <AddButtonContainer>
              <AddButtonInner>
                <Button
                  {...{
                    onClick: () => generate(false, t(`lang.targetDomain`)),
                    text: t('lang.executeAction'),
                    buttonType: "GENERATE",
                  }}
                />
                <Button
                  {...{
                    onClick: () => generate(true, t(`lang.targetDomain`)),
                    text: t('lang.executeSitemap'),
                    buttonType: "GENERATE",
                  }}
                />
              </AddButtonInner>
            </AddButtonContainer>

            <Spacer space={30} />

            <TitleContainer>
              <Title
                type={"SUB_BLOCK_TITLE"}
                tag={"p"}
                text={t('lang.result')}
                align={"left"}
                color={"#888"}
              />
              <div data-tip={t('lang.resultTip')}>
                <a href="https://developer.evergage.com/web-integration/sitemap/examples/ecommerce">
                  <IoInformationCircleOutline
                    color="#888"
                    size={20}
                    style={{ marginBottom: "4px" }}
                  />
                </a>
                <ReactTooltip
                  effect="float"
                  type="info"
                  place="right"
                  backgroundColor="#1a9fda"
                />
              </div>
            </TitleContainer>

            <ResultTextBox {...{ output }}>
              <AddButtonContainer>
                <AddButtonInner>
                  <Button
                    {...{
                      onClick: () => copyResult(t(`lang.copyDone`)),
                      text: t('lang.copy'),
                      buttonType: "COPY",
                      disabled: output.length === 0,
                    }}
                  />
                </AddButtonInner>
              </AddButtonContainer>
            </ResultTextBox>
          </>
        </Container>

        <Spacer space={60} />

        <Toaster
          position="top-center"
          containerClassName=''
          containerStyle={{}}
          toastOptions={{
            // Define default options
            className: '',
            duration: 4000,
            style: {
              background: "#434b66",
              color: "#fff",
              padding: "16px 24px",
            },

            // Default options for specific types
            success: {
              duration: 3000,
              // theme: {
              //   primary: "green",
              //   secondary: "black",
              // },
            },
          }}
        />
      </StyledApp>
    </Suspense>
  );
}


function updateMatchType(
  e: React.ChangeEvent<HTMLSelectElement>,
  urlConditionArray: IUrlConditionValue[],
  setUrlConditionArray: React.Dispatch<
    React.SetStateAction<IUrlConditionValue[]>
  >,
  index: number
) {
  const cloneArray = [...urlConditionArray];
  cloneArray[index].matchType = e.target.value as MatchType;
  setUrlConditionArray(cloneArray);
}

function updateUrlValue(
  setUrlConditionArray: React.Dispatch<
    React.SetStateAction<IUrlConditionValue[]>
  >
) {
  setUrlConditionArray((urlConditionArray) => [
    ...urlConditionArray,
    {
      rowId: GenerateRandomString(5),
      value: '',
      isError: false,
      matchType: 'CONTAIN',
    },
  ]);
}

function getUpdatedUrlConditionArray (
  urlConditionArray: IUrlConditionValue[]
) {
  return urlConditionArray.map(
    (item: IUrlConditionValue) => {
      const { value } = item
      return {
        ...item,
        ...{
          isError: value === ''
        }
      }
    }
  )
}

function updatedContentZoneArray (
  contentZoneArray: IContentZoneValue[]
) {
  return contentZoneArray.map(
    (item: IContentZoneValue) => {
      const { name, value } = item
      return {
        ...item,
        ...{
          isError: name === '' || value === ''
        }
      }
    }
  );
}

const StyledApp = Styled.div`
  padding: 0 12px;
`;

const AddButtonContainer = Styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;

const AddButtonInner = Styled.div`
  width: 400px;
  display: flex;
  gap: 10px;
`;

const StyledSwitch = Styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: 20px;
`;

export default App;
