import Styled from "styled-components";
import Input from "./Input";
import Button from "./Button";
import { IoClose, IoAdd } from "react-icons/io5";
import Select from "./Select";
import { IUrlEdit as Props} from "../interface";
import { useTranslation } from "react-i18next";

const UrlEdit: React.FC<Props> = ({
  value,
  index,
  length,
  isError,
  placeholder,
  onAdd,
  onRemove,
  onChange,
  handleChange,
}) => {
  const { t } = useTranslation();
  
  return (
    <StyledUrlEdit index={index} length={length} role="option">
      <Select handleOnChange={onChange} />

      <div className="urlEditInputContainer">
        <Input
          handleChange={handleChange}
          value={value}
          isError={isError}
          placeholder={placeholder}
        />
      </div>

      <div
        className={`
        urlEditButtonGroupContainer 
        ${
          length > 1 && index === 0 ? "urlEditButtonGroupContainerMultiple" : ""
        }
      `}
      >
        {index === length - 1 && (
          <div className="urlEditButtonContainer">
            <Button
              {...{
                onClick: onAdd,
                text: t(`lang.add`),
                disabled: false,
                icon: <IoAdd />,
                buttonType: "ADD",
                ariaLabel: "add",
              }}
            />
          </div>
        )}
        {index !== 0 && (
          <div className="urlEditButtonContainer">
            <Button
              {...{
                onClick: onRemove,
                text: t(`lang.remove`),
                disabled: false,
                icon: <IoClose />,
                buttonType: "REMOVE",
                ariaLabel: "remove",
              }}
            />
          </div>
        )}
      </div>
    </StyledUrlEdit>
  );
}

const StyledUrlEdit = Styled.div<Pick<Props, "index" | "length">>`
  display: flex;
  margin-top: 12px;
  
  &:first-child {
    margin-top: 0
  }

  .urlEditInputContainer {
    flex: 0 1 100%;
  }

  .urlEditButtonGroupContainer {
    flex: ${({ index, length }) =>
      index === length - 1 ? "1 0 160px" : "1 0 75px"};
    display: flex;
    margin: 0 0 0 8px;
    gap: 10px;
  }

  .urlEditButtonContainer {
    flex: 1 0 auto;
  }

  .urlEditButtonContainer:first-child {
    flex: 1 0 auto;
  }

  .urlEditButtonContainer:last-child {
    flex: 1 1 auto;
  }

  .urlEditButtonGroupContainerMultiple {
    display: none;
  }
`;

export default UrlEdit;
