import { memo } from "react";
import Styled from "styled-components";
import { IInputProps as Props } from "../interface";

const Input: React.FC<Props> = memo(
  ({ handleChange, placeholder, value, isError, ariaLabel }) => {
    return (
      <StyledInput
        placeholder={placeholder}
        value={value}
        isError={isError}
        onChange={handleChange}
        aria-label={ariaLabel}
      />
    );
  }
);

const StyledInput = Styled.input<Pick<Props, "isError">>`
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 12px 16px;
  font-family: roboto, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #495057;
  background-color: ${({ isError }) => (isError ? "#fff3f3" : "#fff")};
  background-clip: padding-box;
  border: 1px solid ${({ isError }) => (isError ? "#bf0000" : "#ced4da")};
  border-radius: 4px;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #ababab;
  }
  :-ms-input-placeholder {
    color: #ababab;
  }
`;

export default Input;
