import { IResultTextBoxProps as Props } from "../interface";
import Styled from "styled-components";

const ResultTextBox: React.FC<Props> = ({ output, children }) => {
  const formatOutput = output.replace(/\n/g, "<br>").replace(/\s/g, "&nbsp;");
  return (
    <div>
      <StyledResult
        contentEditable="true"
        onCut={() => false}
        onPaste={() => false}
        onKeyDown={() => false}
        dangerouslySetInnerHTML={{ __html: formatOutput }}
      />
      <div>{children}</div>
    </div>
  );
}

const StyledResult = Styled.div`
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-family: 'Roboto Mono', monospace;
  font-size: 16px;
  line-height: 1.5;
  padding: 12px 16px;
`;

export default ResultTextBox;
