import React, { ReactNode } from "react";
import Styled from "styled-components";

interface Props {
  children: ReactNode;
}

const TitleContainer: React.FC<Props> = ({ children }) => {
  return <StyledTitleContainer>{children}</StyledTitleContainer>;
}

const StyledTitleContainer = Styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`;

export default TitleContainer;
