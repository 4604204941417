import Styled from "styled-components";
import { IRadioMenuProps as Props } from "../interface";
import media from "styled-media-query";

const RadioMenu: React.FC<Props> = ({ onClick, text, selected, role }) => {
  return (
    <StyledButton
      selected={selected}
      onClick={() => onClick(text)}
      role={role}
    >
      <StyledCircle selected={selected}>
        <div></div>
      </StyledCircle>
      <span>
        <p>{text}</p>
      </span>
    </StyledButton>
  );
}

const StyledButton = Styled.button<Pick<Props, "selected">>`
  display: flex;
  flex: 1 1 50%;
  align-items: center;
  gap: 16px;
  font-size: 18px;
  font-weight: bold;
  color: #3A36F7;
  font-family: roboto, sans-serif;
  padding: 12px 20px;
  border: 2px solid #3A36F7;
  border-radius 12px;
  background: ${({ selected }) => (selected  ? "#dadff1" : "#FFFFFF")};
  cursor: pointer;
  transition: background-color .2s ease-in;

  &:hover {
    background: #CAD3F4;
  }
  &:hover {
    background: ${({ selected }) => (selected  ? "#adbbf0" : "#dadff1")};
  }

  ${media.lessThan("medium")`
    padding: 8px 12px;
    gap: 8px;
    font-size: 16px;
  `}
`;

const StyledCircle = Styled.div<Pick<Props, "selected">>`
  width: 20px;
  height: 20px;
  border: 2px solid ${({ selected }) => (selected ? "#3A36F7" : "#999")};
  border-radius: 50%;
  position: relative;
  background: #fff;

  &:before {
    content: '';
    display: block;
    width: ${({ selected }) => (selected ? "10px" : "0")};
    height: ${({ selected }) => (selected ? "10px" : "0")};
    border: 1px solid #3A36F7;
    border-radius: 50%;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${({ selected }) => (selected ? "#3A36F7" : "#FFFFFF")};
    transition: transform .2s ease;
    transform: scale(${({ selected }) => (selected ? 1 : 0)}, ${({ selected }) => selected ? 1 : 0});
  }
`;

export default RadioMenu;
